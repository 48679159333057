.cmp-button {
  width: fit-content;
  height: fit-content;
  margin: 0.5em 0.5em;
  padding: 0.5625em 1em 0.6875em 1em;
  border-style: solid;
  border-width: 0.125em;
  border-radius: 1.56em;
  font-family: defaultFont, serif;

  &.link {
    border: none;
  }
}
.btn, .link {
  font-family: defaultFont, serif;
}
.cmp-button__text {
  font-weight: 300;
  text-decoration: none;
}
.btn--centered {
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: fit-content;
}
