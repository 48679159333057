.cmp-tabs {
  margin: 0.5em;
}

.cmp-tabs__tablist {
}
.cmp-tabs__tab {
  height: 2.625em;
  margin: 0;
  padding: 0.5625em 1em 0.6875em 1em;
  color: $color-shade-3;
  font-size: 1em;
  outline: none;
  &:hover {
    color: $color-black;
  }
}

.cmp-tabs__tab--active {
  color: $color-black;
  border: none;
  &:focus {
    border: none;
  }
}

.cmp-tabs__tabpanel {
}
.cmp-tabs__tabpanel--active {
  padding-right: 0.5em;
  padding-left: 0.5em;
}


//######## VB Tabs Component #########//
.cmp-tabs-slider__tab {
  align-items: center;
}

.cmp-tabs-slider__line {
  @media screen and (min-width: size(large)) {
    width: 410px;
  }
}