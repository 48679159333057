//== XF Footer style, used on page template

#main-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  position: relative;

  @media screen and (min-width: size(small)) {
    height: 80px;
    max-width: $grid-huge + 50px;
    margin: auto;
  }

  @media screen and (min-width: size(large)) {
    height: 130px;
  }

  @media screen and (min-width: size(huge)) {
    max-width: $grid-huge + $margin*2;
  }

  .separator {
    width: 100%;
  }

  & .cmp-container {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    width: fit-content;

    @media screen and (min-width: size(small)) {
      right: auto;
      top: 12px;
    }
  }

  .image {
    margin: 0 18px;
    width: 32px;
  }

  .text {
    width: 100%;
  }

  .cmp-text {
    margin-top: 60px;

    @media screen and (min-width: size(small)) {
      margin-top: 29px;
    }


    & p {
      margin-block: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (min-width: size(small)) {
        flex-direction: row;
        justify-content: center;
      }

      & a {
        color: $color-black;
        text-decoration: none;

        &:focus {
          color: $color-tint-5;
          border: 0.125em solid $color-black;
          border-radius: 0.25em;
        }
        &:hover {
          color: $color-tint-5;
        }
      }
    }
  }
}
