
//== Defaults

html, body {
  margin: 0;
  font-size: $font-size;
  font-family: defaultFont, serif;
  line-height: $font-height;
  background: $color-white;
  color: $color-black;
}

a {
  color: $color-black;
}

p {
  line-height: 28px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block: 0;
  font-family: titleFont, serif;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

//== Grid

$max_col: 12;

// Default breakpoint
.aem-Grid {
  @include generate-grid(default, $max_col);
}

// Phone breakpoint
@media (max-width: 768px) {
  .aem-Grid {
    @include generate-grid(phone, $max_col);
  }
}
