.block.content .cmp-block__bg-image {
  width: 100%;
  object-fit: cover;
}

.partnerblock {
  margin: 0;

  .multicolumn .cmp-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}